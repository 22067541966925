:root {
  --bs-primary-yellow: #ffc800;
  --bs-primary-yellow-rgb: 255, 200, 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

main {
  min-height: 80vh;
}

header {
  border-top: 10px solid #ffffff;
}

footer {
  border-top: 0.5px solid #ced4da;
}

.col-4 {
  align-self: center;
}

.col-sm-3 {
  background: aliceblue;
}

.col-sm-9 {
  background: aliceblue;
}

.text-center.py-3.m-5.col {
  color: white;
}

.text-center.py-3.m-5.col {
  color: white;
}

.container-fluid {
  margin-top: 0px;
  padding: 0;
}

img.d-block.w-100 {
  border: 2px solid thistle;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel a {
  margin: 0 auto;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.text-center.col-2 {
  padding-left: 0;
  padding-right: 0;
}

.col-6 {
  padding-left: 0;
  padding-right: 0;
}

.text-center.col-4 {
  padding-left: 0;
  padding-right: 0;
}

.col-lg-6.col-md.order-lg-2.order-md-12 {
  padding-left: 0;
  padding-right: 0;
}

main.py-3 {
  padding-top: 0px !important;
}

/* test */
@media (min-width: 370px) {
  .navbarEventImage,
  .logo,
  .nav-chapterName {
    height: 75px;
  }
}

@media (min-width: 576px) {
  .navbarEventImage,
  .logo,
  .nav-chapterName {
    height: 100px;
  }
}

@media (min-width: 768px) {
  .navbarEventImage,
  .logo,
  .nav-chapterName {
    height: 100px;
  }
}

@media (min-width: 992px) {
  .navbarEventImage,
  .logo,
  .nav-chapterName {
    height: 100px;
  }
}

@media (min-width: 1200px) {
  .navbarEventImage,
  .logo,
  .nav-chapterName {
    height: 100px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }

  .navbarEventImage,
  .logo,
  .nav-chapterName {
    height: 135px;
  }
}

@media (min-width: 1600px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl {
    max-width: 1530px;
  }

  .navbarEventImage,
  .logo,
  .nav-chapterName {
    height: 135px;
  }
}

.ck-editor__editable {
  min-height: 200px;
  /* max-height: 200px; */
  /* min-width: 860px; */
}

#pdfLoader {
  position: relative;
  top: 0;
  left: 0;
  width: 100;
  height: 100vh;
  background-color: rgba(155, 155, 155, 0.7);
}

.Pdf-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Pdf-logo {
    animation: Pdf-logo-spin infinite 5s linear;
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  }
}

@keyframes Pdf-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* PDF CSS */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

.pdfApp {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pdfApp-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .pdfApp-logo {
    animation: pdfApp-logo-spin infinite 5s linear;
  }
}

@keyframes pdfApp-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#loader {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(155, 155, 155, 0.7);
}

.clickable {
  cursor: pointer;
}

.blink_me {
  /* animation: blinker 1s linear infinite; */
  /* -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite; */
  animation: glowing 1500ms infinite;
}

/* @keyframes blinker {
  50% {
    opacity: 0;
  }
} */
/* @-webkit-keyframes glowing {
  0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
}

@-moz-keyframes glowing {
  0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
}

@-o-keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
} */

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }

  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }

  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

.test-swal {
  width: 80%;
  height: 80vh;
}

.image-lightbox {
  position: relative;
}

.image-delete {
  position: absolute;
  top: -230%;
  left: 85%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 5px;
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.image-delete:hover {
  background-color: black;
}

.videobox {
  position: relative;
}

.close-video-player {
  position: absolute;
  top: -290%;
  left: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 5px;
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.close-video-player:hover {
  background-color: black;
}

.container-x {
  max-width: 1280px;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
}

#subscription h1 {
  font-size: 1.6rem;
}

.bg-main {
  background: #ffbc00;
  color: #fff;
}

.custom-btn {
  background-color: #033c73;
  color: white;
  padding: 10px;
}

/* css from mahadi */
header.masthead {
  border: 1rem;
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  text-align: center;
  color: #fff;
  /* background-image: url("../assets/img/header-bg.jpg"); */
  background-image: url("./assets/img/header-bg.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

header.masthead .masthead-subheading {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 25px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

header.masthead .masthead-heading {
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 3.25rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (min-width: 768px) {
  header.masthead {
    border: 1rem;
    padding-bottom: 12.5rem;
  }

  header.masthead .masthead-subheading {
    font-size: 2.25rem;
    font-style: italic;
    line-height: 2.25rem;
    margin-bottom: 2rem;
  }

  header.masthead .masthead-heading {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 4.5rem;
    margin-bottom: 1.5rem;
  }
}

#portfolio .portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  margin: 0 auto;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255 200 0 / 25%);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
}

#portfolio
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content {
  font-size: 1.25rem;
  color: white;
}

#portfolio .portfolio-item .portfolio-link:hover .portfolio-hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-caption {
  padding: 1.5rem;
  text-align: center;
  /* background-color: #fff; */
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-heading {
  font-size: 1.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-bottom: 0;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-subheading {
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;
}

.portfolio-modal .modal-content h2,
.portfolio-modal .modal-content .h2 {
  font-size: 3rem;
  line-height: 3rem;
}

.portfolio-modal .modal-content p.item-intro {
  font-style: italic;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-content p {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content ul.list-inline {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content img {
  margin-bottom: 2rem;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

.btn-primary-yellow {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.btn-primary-yellow:hover {
  color: #fff;
  background-color: #d9aa00;
  border-color: #cca000;
}

.btn-check:focus + .btn-primary-yellow,
.btn-primary-yellow:focus {
  color: #fff;
  background-color: #d9aa00;
  border-color: #cca000;
  box-shadow: 0 0 0 0.25rem rgba(255, 208, 38, 0.5);
}

.btn-check:checked + .btn-primary-yellow,
.btn-check:active + .btn-primary-yellow,
.btn-primary-yellow:active,
.btn-primary-yellow.active,
.show > .btn-primary-yellow.dropdown-toggle {
  color: #fff;
  background-color: #cca000;
  border-color: #bf9600;
}

.btn-check:checked + .btn-primary-yellow:focus,
.btn-check:active + .btn-primary-yellow:focus,
.btn-primary-yellow:active:focus,
.btn-primary-yellow.active:focus,
.show > .btn-primary-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 208, 38, 0.5);
}

.btn-primary-yellow:disabled,
.btn-primary-yellow.disabled {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.btn-xl {
  padding: 1.25rem 2.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 700;
}

.page-section {
  padding: 2rem 0;
}

.page-section h2.section-heading,
.page-section .section-heading.h2 {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.page-section h3.section-subheading,
.page-section .section-subheading.h3 {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 4rem;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after,
.timeline > li:before {
  display: table;
  content: " ";
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #ffc800;
}

.timeline > li .timeline-image h4,
.timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4,
.timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading,
.timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }

  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .timeline > li .timeline-image h4,
  .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline > li .timeline-image h4,
  .timeline > li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline > li .timeline-image h4,
  .timeline > li .timeline-image .h4 {
    margin-top: 40px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

/* .img-fluid {
  max-width: 100%;
  height: auto;
} */

.text-primary-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-yellow-rgb), var(--bs-text-opacity)) !important;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #997800;
  background-color: #fff4cc;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #997800;
  background-color: #e6dcb8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #997800;
  border-color: #997800;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #087990;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #087990;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #087990;
  border-color: #087990;
}

.list-group-item-warning {
  color: #997800;
  background-color: #fff4cc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #997800;
  background-color: #e6dcb8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #997800;
  border-color: #997800;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #959596;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #959596;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #959596;
  border-color: #959596;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.list-group-item-bronze {
  color: #0f5132;
  background-color: #e49444;
}

.list-group-item-silver {
  color: #0f5132;
  background-color: #bbc2cc;
}

.list-group-item-gold {
  color: #0f5132;
  background-color: #ffd700;
}

.list-group-item-platinum {
  color: #0f5132;
  background-color: #d0eded;
}

.list-group-item-diamond {
  color: #0f5132;
  background-color: #f39f9f;
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;
}

.portfolio-modal .modal-content h2,
.portfolio-modal .modal-content .h2 {
  font-size: 3rem;
  line-height: 3rem;
}

.portfolio-modal .modal-content p.item-intro {
  font-style: italic;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-content p {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content ul.list-inline {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content img {
  margin-bottom: 2rem;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

section#contact {
  /* background-color: #212529; */
  background-color: #033c73;
  /* background-image: url("../assets/img/map-image.png"); */
  background-repeat: no-repeat;
  background-position: center;
}

section#contact .section-heading {
  color: #fff;
}

section#contact form#contactForm .form-group {
  margin-bottom: 1.5rem;
}

section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
  padding: 1.25rem;
}

section#contact form#contactForm .form-group input.form-control {
  height: auto;
}

section#contact form#contactForm .form-group-textarea {
  height: 100%;
}

section#contact form#contactForm .form-group-textarea textarea {
  height: 100%;
  min-height: 10rem;
}

section#contact form#contactForm p.help-block {
  margin: 0;
}

section#contact form#contactForm .form-control:focus {
  border-color: #ffc800;
  box-shadow: none;
}

section#contact form#contactForm ::-webkit-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm :-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm ::-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm :-ms-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

/* css from mahadi end */
